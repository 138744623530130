<template>
    <div>
        <div class="institution-header contant">
            <div class="search-nav">
                <div class="search-form">
                    <el-form :inline="true" ref="ruleForm" :model="listQuery">
                        <div class="form">
                            <div class="search-conditions">
                                <el-form-item label="IMEI" prop="imeis">
                                    <el-autocomplete class="input-w290-h32" v-model="listQuery.imeis" placeholder="请输入设备/IMEI" :trigger-on-focus="false" :fetch-suggestions="querySearch"></el-autocomplete>
                                </el-form-item>                                
                                <el-form-item label="时间范围" prop="startDate" class="mr12">
                                    <el-date-picker align="right" size="small" v-model="listQuery.startDate" type="datetime" placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerStartDate"></el-date-picker>
                                </el-form-item>
                                <el-form-item class="mr12">
                                    <span>至</span>
                                </el-form-item>
                                <el-form-item prop="endDate">
                                    <el-date-picker align="right" size="small" v-model="listQuery.endDate" type="datetime"
                                        :default-time="new Date()+''" placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss"
                                        :picker-options="pickerEndDate">
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <div class="search-btns">
                                <el-form-item class="clear-margin-bottom clear-margin-right" label="" prop="childFlag">
                                    <el-checkbox v-model="listQuery.childFlag">包含下级</el-checkbox>
                                </el-form-item>
                                <tem-btn  type="primary" value="查询" @click="handleSearch('search')"></tem-btn>
                                <tem-btn  type="info" value="清空" @click="restForm('ruleForm')"></tem-btn>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
            <div class="table-list">
                <Table :table="table" :total="listQuery.total" :page.sync="listQuery.page" :size.sync="listQuery.size"
                :orderBy.sync="listQuery.orderBy" @pagination="getAlarmManagementList"></Table>
            </div>
        </div>
        <contact-details v-model="contactDetailsDialog" :toSeeConcat="toSeeConcat" :userList="userList"
            @getUserList="getUserList" />
    </div>
</template>

<script>
    import {getOrgCode} from '@/utils/cookie'
    import {
        alarmSummary,
        getUserListByOrganizationId
    } from '@/api/statisticalReport/alarmManagement';
    import Tree from '@/components/tree/tree';
    import Table from '@/components/table/table';
    import ContactDetails from './components/contactDetails'
    import {
        deviceTypeList,
        deviceMatching
    } from '@/api/deviceManagement';
    import {
        getDeviceType
    } from '@/api/statisticalReport/lowBatteryStatistics';
    export default {
        components: {
            Table,
            Tree,
            ContactDetails
        },
        data() {
            return {
                contactDetailsDialog: false, //查看联系人
                listQuery: {
                    childFlag: false, //复选框
                    date: null, //日期
                    startDate: '', // 开始时间
                    endDate: '', // 结束时间
                    /**分页 */
                    total: 0, //分页总条数
                    page: 1, //分页页码
                    size: 10, //每页数据大小
                    organizationId: null, //组织id
                    deviceTypeDetailId: null, //设备类型id
                    imeis: '', //设备输入框
                    orderBy: '' //排序
                },
                activeNames: '', // 折叠面板
                pickerStartDate: {
                    disabledDate: (time) => { // 限制开始时间小于结束时间
                        // if (this.listQuery.endDate != "") {
                        //     return time.getTime() > Date.now() || time.getTime() > new Date(this.listQuery.endDate);
                        // } else {
                        //     }
                        return time.getTime() > Date.now();
                    }
                },
                // 结束时间
                pickerEndDate: {
                    disabledDate: (time) => { // 限制开始时间小于结束时间
                        // if (this.listQuery.startDate != "") {
                        //     return time.getTime() > Date.now() || time.getTime() < new Date(this.listQuery.startDate)-8.64e7;
                        // } else {
                        //     }
                        return time.getTime() > Date.now();
                    }
                },
                table: {
                    configItem: true, //是否开启表格项配置
                    stripe: false, //是否为斑马纹
                    loading: false, // 数据加载loading
                    data: [], //表格渲染数据
                    header: [{
                            type: 'index',
                            label: '序号',
                            width: '55',
                            ifShow: true
                        },
                        {
                            prop: 'imei',
                            label: 'IMEI',
                            minWidth: '180',
                            tooltip: true,
                            ifShow: true
                        }, 
                        {
                            prop: 'deviceName',
                            label: '设备名称',
                            minWidth: '180',
                            tooltip: true,
                            ifShow: true
                        },                                               
                        {
                            prop: 'deviceModel',
                            label: '设备型号',
                            minWidth: '180',
                            tooltip: true,
                            ifShow: true
                        },
                        {
                            prop: 'sim',
                            label: 'SIM卡号',
                            minWidth: '180',
                            tooltip: true,
                            ifShow: true
                        },
                        {
                            prop: 'orgName',
                            label: '组织机构',
                            minWidth: '180',
                            tooltip: true,
                            ifShow: true
                        },
                        {
                            prop: 'outOfFence',
                            label: '离开围栏告警',
                            minWidth: '120',
                            fixed: 'right',
                            ifShow: true,
                            render: (h, param) => {
                                return h('div', [
                                    h('span', {
                                        style: {
                                            marginRight: '8px',
                                            color: 'blue',
                                            cursor: 'pointer',
                                        },
                                        on: {
                                            click: () => {
                                                this.$router.push({
                                                    name: 'alarmManagement',
                                                    params: {
                                                        imei: param.imei,
                                                        alarmTypes:'OUT_OF_FENCE'
                                                    }
                                                })
                                            }
                                        }
                                    }, param.outOfFence),
                                ]);
                            }
                        },
                        {
                            prop: 'intoFence',
                            label: '进入围栏告警',
                            minWidth: '120',
                            fixed: 'right',
                            tooltip: true,
                            ifShow: true,
                            render: (h, param) => {
                                return h('div', [
                                    h('span', {
                                        style: {
                                            marginRight: '8px',
                                            color: 'blue',
                                            cursor: 'pointer',
                                        },
                                        on: {
                                            click: () => {
                                                this.$router.push({
                                                    name: 'alarmManagement',
                                                    params: {
                                                        imei: param.imei,
                                                        alarmTypes:'INTO_FENCE'
                                                    }
                                                })
                                            }
                                        }
                                    }, param.intoFence),
                                ]);
                            }
                        },
                        {
                            prop: 'lowPower',
                            label: '低电告警',
                            minWidth: '120',
                            fixed: 'right',
                            tooltip: true,
                            ifShow: true,
                            render: (h, param) => {
                                return h('div', [
                                    h('span', {
                                        style: {
                                            marginRight: '8px',
                                            color: 'blue',
                                            cursor: 'pointer',
                                        },
                                        on: {
                                            click: () => {
                                                this.$router.push({
                                                    name: 'alarmManagement',
                                                    params: {
                                                        imei: param.imei,
                                                        alarmTypes:'3'
                                                    }
                                                })
                                            }
                                        }
                                    }, param.lowPower),
                                ]);
                            }
                        },
                        {
                            prop: 'offline',
                            label: '离线告警',
                            minWidth: '120',
                            fixed: 'right',
                            tooltip: true,
                            ifShow: true,
                            render: (h, param) => {
                                return h('div', [
                                    h('span', {
                                        style: {
                                            marginRight: '8px',
                                            color: 'blue',
                                            cursor: 'pointer',
                                        },
                                        on: {
                                            click: () => {
                                                this.$router.push({
                                                    name: 'alarmManagement',
                                                    params: {
                                                        imei: param.imei,
                                                        alarmTypes:'4'
                                                    }
                                                })
                                            }
                                        }
                                    }, param.offline),
                                ]);
                            }
                        },
                    ]
                },
                userList: {}, //根据组织机构id获取的用户列表
                restaurants: [], // 模糊匹配数组
                deviceTypeList: [], //设备类型列表
                toSeeConcat: {
                    page: 1, // 当前页
                    size: 10, // 每页显示数
                    organizationId: null
                },
                dataObj: {}
            }
        },
        mounted() {
            if (this.$route.params.imei) {
                this.listQuery.imeis = this.$route.params.imei + '';
            }
            if(this.$route.params.hasOwnProperty('organizationId')) {
                //获取当前登录用户的组织id
                this.listQuery.organizationId = this.$route.params.organizationId;
            } else {
                //获取当前登录用户的组织id
                this.listQuery.organizationId = this.$store.getters.organizationId;
            }
            if(this.$route.params.hasOwnProperty('childFlag')){
                this.listQuery.childFlag = this.$route.params.childFlag;
            }
            //获取默认数据列表
            this.getAlarmManagementList();
            //获取设备类型列表
            this.getDeviceTypeList();
        },
        methods: {
            /**
             * 树形结构点击事件
             * @param { Object } node 当前点击节点
             */
            currentNode(node) {
                this.listQuery.organizationId = node.id;
                this.getAlarmManagementList();
            },
            /**
             * 获取设备类型列表
             */
            getDeviceTypeList() {
                getDeviceType({
                    openPage: '1'
                }).then((res) => {
                    if (res.data.code == 0) {
                        res.data.data.rows.forEach((v, i, r) => {
                            let deviceTypeObj = {};
                            deviceTypeObj.value = v.id;
                            deviceTypeObj.label = v.deviceModel;
                            this.deviceTypeList.push(deviceTypeObj);
                        })
                    }
                })
            },
            /**
             * 通过组织id 获取用户信息列表
             * @param {Number } organizationId 组织 id
             */
            seeUserList(val) {
                this.toSeeConcat.size = 10;
                this.toSeeConcat.page = 1;
                this.toSeeConcat.organizationId = val;
                this.getUserList();
            },
            /** 模糊查询imei */
            querySearch(queryString, cb) {
                if (queryString.trim() == '') {
                    cb([])
                    return;
                }
                deviceMatching({
                    imei: queryString
                }).then(res => {
                    if (!res.data.code) {
                        let arr = [];
                        for (var i = 0; i < res.data.data.length; i++) { // 处理成模糊查询的数据
                            let obj = {};
                            obj.value = res.data.data[i];
                            arr.push(obj);
                        }
                        cb(arr);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            /**
             * 分页切换的方法
             * @param { Object } val 分页传过来的数据
             */
            getUserList(val) {
                //分页切换  更新父组件的值
                this.toSeeConcat.size = val ? val.size : this.toSeeConcat.size;
                this.toSeeConcat.page = val ? val.page : this.toSeeConcat.page;
                const paramUser = {
                    limit: this.toSeeConcat.size,
                    organizationId: this.toSeeConcat.organizationId,
                    page: this.toSeeConcat.page,
                }
                getUserListByOrganizationId(paramUser).then((res) => {
                    if (res.data.code == 0) {
                        this.userList = res.data.data;
                    }
                })
            },
            /**
             * 获取统计管理数据列表
             */
            getAlarmManagementList(val) {
                if (this.listQuery.startDate > this.listQuery.endDate) {
                    this.$message.warning('开始时间大于结束时间');
                    return;
                }
                const param = {
                    includeSub: this.listQuery.childFlag,
                    startTime: this.listQuery.startDate ? this.listQuery.startDate : null,
                    endTime: this.listQuery.endDate ? this.listQuery.endDate : null,
                    organizationId: this.listQuery.organizationId,
                    imei: this.listQuery.imeis,
                    pageSize: this.listQuery.size,
                    pageIndex: this.listQuery.page,
                    deviceTypeDetailId: this.listQuery.deviceTypeDetailId,
                    orderBy: this.listQuery.orderBy || '',
                    fullUserId:getOrgCode()
                }
                if(val == "search"){
                    param.pageIndex =1 ;
                }
                this.dataObj = JSON.parse(JSON.stringify(param));
                alarmSummary(param).then((res) => {
                    if (res.code == 200) {
                        this.table.data = res.data;
                        this.listQuery.total = res.count;
                    } else {
                        this.table.data = [];
                        this.listQuery.total = 0;
                    }
                })
            },
            /**
             * 搜索
             */
            handleSearch(val) {
                this.getAlarmManagementList(val);
            },
            /**
             * 重置搜索导航栏
             * @param { Object } formName 当前表单的ref值
             */
            restForm(formName){
                this.$refs[formName].resetFields();
            },
        },
        destroyed() {
            this.$store.dispatch('user/sevaImei', '');
        }
    }

</script>
<style lang="scss" scoped>
    // .contant {
    //     margin-top: 20px;

    //     .page {
    //         float: right;
    //         margin-top: 10px;
    //     }

    //     .more-search {
    //         margin: 10px 0;

    //         .el-collapse-item__arrow {
    //             margin: 0;
    //         }

    //         .option-btn {
    //             margin-top: 10px;
    //         }
    //     }
    // }

</style>
